import {
  EuiAccordion,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import React from "react";
import { useLocation } from "react-router-dom";
import { MarkdownFormat } from "../MarkdownFormat";

const buttonContent = (title: string, id: string) => (
  <div id={id}>
    <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon type="questionInCircle" size="m" />
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiTitle size="xs">
          <h3>{title}</h3>
        </EuiTitle>
      </EuiFlexItem>
    </EuiFlexGroup>
  </div>
);

export const Faq = () => {
  const { hash } = useLocation();
  return (
    <div>
      {sections.map(s => (
        <EuiAccordion
          key={`${s.hashId}AccordinId`}
          id={`${s.hashId}AccordinId`}
          className="euiAccordionForm"
          buttonClassName="euiAccordionForm__button"
          buttonContent={buttonContent(s.title, s.hashId)}
          extraAction={
            <EuiCopy
              textToCopy={`${
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname
              }#${s.hashId}`}
            >
              {copy => (
                <EuiToolTip title="Copy section url">
                  <EuiButtonIcon
                    onClick={copy}
                    iconType="link"
                    aria-label="Copy url"
                  />
                </EuiToolTip>
              )}
            </EuiCopy>
          }
          paddingSize="l"
          initialIsOpen={hash === `#${s.hashId}`}
        >
          <MarkdownFormat>{s.text}</MarkdownFormat>
        </EuiAccordion>
      ))}
    </div>
  );
};

const communityResources = `
**What is racecraft.online?**
Great video from our fellow driver eKlasik where he describes the basis of how to use and navigate racecraft.online

!{video{"id":"2Fqa2HltlJc","title":"What is racecraft.online?", "maxWidth": "600px" }}
&nbsp;  
&nbsp;  
&nbsp;  

**Wie Kann ich bei RaceCraftOnline mit Fahren**
Ein Video für unsere deutschsprachigen Fahrer

!{video{"id":"wpjzJEpU11w","title":"Wie Kann ich bei RaceCraftOnline mit Fahren", "maxWidth": "600px" }}
&nbsp;  
&nbsp;  
&nbsp;  

**Get hyped about racecraft.online! :)**

!{video{"id":"gka9VxgAP1U","title":"Will this Change Automobilista 2 Forever? Racecraft Online!", "maxWidth": "600px" }}
`;

const ams2Multi = `
Be aware that **AMS2 multiplayer is not fully stable yet**.  

Our stats shows that drivers are :skull:**disconnected in 5% of their races on average**. Some drivers are disconnected more ofter, some don't experience disconnections at all.  

Wheh you experience a disconnection, please post your logs to Reiza forum as explained in this [thread](https://forum.reizastudios.com/threads/multiplayer-logs-reports-thread.32731/).

Thare are few community fixes for AMS2 multiplayer like this [Reddit post](https://www.reddit.com/r/AUTOMOBILISTA/s/L50FdHsdDO), but I am not sure if they are working or not.  

Also take a look at this [FAQ entry](https://www.racecraft.online/faq#serverDisconnects)
  `;
const rcoIntro = `
# Welcome to racecraft.online (RCO)

RCO is the place for online ranked multiplayer racing!

Register for races 24 hours a day and earn points for the season. S.Elo, Safety, and Consistency stats track your performance. Can you move up the licenses from Rookie to Alien?

If less than 6 racers are singed up, grids are completed with AI drivers that adapt to your skill level to help improve your race craft online.

Sign-up and register for races today!

[Races](https://www.racecraft.online) | [Stats](https://www.racecraft.online/stats) | [Standings](https://www.racecraft.online/standings) | [FAQ](https://www.racecraft.online/faq)
`;

const adaptiveAiText = `
**AMS2** online races can be filled up with AI drivers. \`RCO\` tracks yours and AI times and is trying to match AI to your skill level. 

You need to complete at least one race with given track and car combo so \`RCO\` can get yours and AI lap times. After that, in the next race with given car and track, the Adaptive AI should be adapted to you.  
Adaptive AI is using your 10 best laps avearge to match against AI lap times. It's designed to put you in the middle of the field, it's a training tool which will push your limits over and over again.
Adaptive AI will continuously adapt to your skill, faster lap times will give you faster AI.

In races where \`RCO\` doesn't know your or AI laptimes (for given track and car combo), it will use your last known AI skill or default one, which is around 90. 
AMS2 AI (and also human drivers) are not consistent between tracks and cars, for some combos your AI skill could be 100, for others 90 or 110, there is no hard written rule for that.  


In races with multiple human opponents, the average of calculated AI skills are used as AI skill for a given race.  
Keep in mind that it's difficult to match AI to all drivers in given race.

When in doubt do 2 races in with one car and track combo, the AI in 2nd race should be adapted to you.
`;
const serversText = `
**Race servers** are run on demand, that's why you need to wait around 2-3 minutes before the race for server to boot up.  If no one is registered for race, then race server will not boot up.  
Race servers access is whitelisted, only drivers registered to a given race can join it.  
**Practice servers** are joinable for anyone who own valid race license for given race series.
`;

const structureText = `
racecraft.online can hosts multiple \`Race Series\`, each \`Race Series\` contains multiple weekly \`Events\`, \`Event\` contains multiple \`Races\` and \`Races\` can contain multiple \`Splits\`, with fastest drivers in the top \`Split\`.
`;

const weatherText = `
Currently weather is set as random, but for **AMS2** it means that the weather will be similar to usual real world weather on given track on a given time of the year, so depending on track location you can expect rain in the autumn and sun in the summer.
`;

const sessionsDateAndTimeText = `
**In game date** is set as current (today) date. **Time** is set randomly, for cars without headlights and for tracks  without ligths, the **races** are held during the **day**, otherwise race can happen at **night**.  
**Practice** session are always held during the **day**.  
**Race** starts 2 hours after the qualyfication session.
`;

const regionsText = `
Sessions are hosted on world regions choosed by users during account creation.  
For races, where drivers from multiple world regions are registered, the region majority will take precedense.  
Practice sessions are hosted on multiple world regions, RCO will automatically direct you to practice session with free slots, no matter in which world region it is hosted.
`;
const quickStartText = `
To start racing:

1. Create an account with your username and password
1. Link your Steam account
1. Complete a [Clean Laps Challenge](https://www.racecraft.online/faq#CLC):
    1. Find the series you want to compete in and click "Practice".
    1. Join the server and complete 3 clean laps
1. Register for the next session:
    1. Click "Register to race"
1. Just join the indicated server, and RCO will automatically track your racing.
`;

const raceCraftOnlineText = `
\`racecraft.online\` (\`RCO\` for short) is a free ranked multiplayer service for sim racers built mostly by 1 and half guy :smile:
Basically \`RCO\` hosts races and the race results are automaticaly transformed in to \`RCO\` custom rating.  

\`racecraft.online\` is about :trophy: competition. It's about clean racing in friendly environment. It's about free and easy access to online races for every sim you want. It's about drivers rating and racing license uniformity between every sim. 

So what \`RCO\` has to offer?  
:white_check_mark: Clean races  
:white_check_mark: Driver rating  
:white_check_mark: Online racing career  
:white_check_mark: Racing licenses  
:white_check_mark: Racing seasons  
:white_check_mark: Races around the clock  
:white_check_mark: Race servers around the globe  
:white_check_mark: and more :smile:  

Currently \`RCO\` supports AC and AMS2, but if there is demand the support for other sims can be addedd.
`;
const bugsText = `
There is 100% chance that things will break, there will be bugs, and they will annoy you. We will fix those bugs.   

\`RCO'\ has a Discord server, you can find support there https://discord.gg/2ZrGzF8hg7

Be aware that we're building \`racecraft.online\` in our free time and we both have families, and full time jobs. We'll not be able to address all the issues right away. Please be patient :wink:
`;

const seasonsText = `
**Overview**  
racecraft.online hosts multiple \`Race Series\`, each \`Race Series\` contains multiple weekly \`Events\`. \`Event\` contains multiple races.

**Seasons**  
Each racing \`Season\` last for 6 weeks and there are 6 \`Events\` within one \`Season\`.

**Your Official results**  
\`Season\` have 6 \`Events\`, you need to participate in at least 3 \`Events\` for your results/points to become \`Official\`.  
If you participated in more than 3 \`Events\`, then your points are sum of points from your best 3 \`Events\`.
`;

const pointsText = `
**Points**  
Each race will grant you some points which are tracked during the season. Driver with mosts points within given \`Race Series\` wins that \`Race Series\` \`Season\`.
During each \`Event\` you can  race in multiple races. Average points from your best 25% races (rounded up for the next whole number) will be counted as points for given \`Event\`. 

**Points distribution**   
In race with 30 participants where all drivers had the same \`S.Elo\`.  

|Position|1st|2nd|3rd|4th|5th|6th|7th|8th|9th|10th|11th|12th|13th|14th|15th|16st|17th|18th|19th|20th|21th|22th|23th|24th|25th|26th|27th|28th|29th|30th|
|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|
| \`Points\`|35|32|30|28|26|25|24|23|22|21|20|19|18|17|16|15|14|13|12|11|10|9|8|7|6|5|4|3|2|1|


Points gained are affected by average S.ELo within the given race. 
In case of  less then 30 participants the points are distributed proportionally to participants count.
`;

const cleanLapsChallengeText = `
**Overview**  
Before your first race you need to prove that you know given race track - you need to pass \`Clean Laps Challenge\` in online practice session. 

**How to**   
Just click \`Practice\` button in the race series you want to race and follow in game chat instructions.  
You need to do 3 consecutive clean laps, no impact or track cut, after 3 clean laps you will be able to register to any race on that track.  

Clean Lap Challenge is done on per track layout basis. After finishing CLC the given track layout is "unlocked" for you forever for every race series.
`;
const officialRacesText = `
Races are classified as official when more than 6 drivers complete the race.

[Currently all races are official]
`;
const ratingText = `
**Overview**  
RCO tracks driver Overall Rating (all races) and Race License Rating (races within a given race license, e.g Rookie), but it only tracks rating from Official races.

**Rating calculation**  
Your Rating is based on your 5 last races.  
Overall Rating is based on your last 5 races.  
Race License Rating is based on your last 5 races within given race license.  
You can't grind rating, it's your skill representation over a given time.  


**Why my rating has dropped after good race??**  
Just calculate your 5 last races rating average if you're unsure why your rating has change after the race.  
If you have raced in less then 5 races, the missing races are calculated as with ratings at 50.

**Rating types**
There are 2 types of rating: Safety, and Consistency.  

**Safety**
Safety is based on your incidents per minute. Be aware that if you rage quit after incident, your incident per minute will be high and your Safety will drop significantly. It's better to finish the race to reduce the incidents/minute ratio All drivers who took part in an incident will get a Safety penalty.  

**Consistency**
Consistency is based on your lap times. The more consistent your lap time, the more your Consistency rating increases.  

Your ratings will be lowered if you don't finish the race.
`;

const raceLicenseDemotionText = `
Your racing license will be demoted if your \`Overall Rating\` or \`Race License Rating\` fall below race license rating requirements minus five. 
Example:  
Your current race license is \`Bronze\` (requirements Safety: 60, Consistency: 60), after last race your Safety dropped below 55, in that case you will be demoted to preceding race license.  
There is an \`adjustment\` period which lasts for first 6 races in your new license, in that period your license will not demoted.

To be promoted again you need to do at least 5 races in preceding race license series and meet rating requirements for given race license.
`;
const raceLicenseText = `
**Overview:**

Each new driver starts with \`Rookie\` race license, this license will allow you to race in \`Rookie\` series.
There are multiple race licenses in racecraft.online:  
\`Rookie\`, \`Bronze\`, \`Silver\`, \`Gold\`, \`Platinum\` and \`Alien\`.  
Each race license will allow you to race in different \`Race Series\`.  
Each race license, except \`Rookie\` license, have specific \`Rating\` requirements. Usually you need to meet specific \`Safety\` and \`Consistency\` requirements.
`;
const raceLicenseRequirementText = `
**Race license rating requirements:**  
\`Rookie\`: no rating requirements  
\`Bronze\`: Safety 60, Consistency 60  
\`Silver\`: Safety 70, Consistency 70  
\`Gold\`: Safety 80, Consistency 80  
\`Platinum\`: Safety 90, Consistency 90  
\`Alien\`: Safety 95, Consistency 95  
`;
const raceLicensePromotionText = `
It can sound complicated but in reality it's not :wink:

**Race license promotion:**  
To get promotion to the next racing license you need to do 5 races in preceding race license series (races don't need to be consecutive), for example:  
To get promotion from \`Rookie\` to \`Bronze\` you need to do at least 5 races in \`Rookie\` series and your \`Overall rating\` and \`Race License rating\` (\`Rookie\` in that case) should meet \`Bronze\` license rating requirements. Then to get promotion to \`Silver\` you need to do at least 5 races in \`Bronze\` series and so on.

You can only be promoted from the race in preceding race license series and only when your \`Overall\` and \`Race License\` ratings are meeting given race license requirements after that race.

After promotion you have 5 races to prove that you're worthy :smile: if after 5 races in series within your new license your rating will drop below required level, you will be demoted to preceding license
`;

const splitsText = `
There can be multiple splits for a given race, grid size determines how drivers will be splitted for a given race.  
For example with grid size of 20 if there are 21 drivers then \`RCO\` will create two races/splits one with 11 an second with 10 drivers.
`;

const accountCreationText = `
Every new account needs to be confirmed trough email. Unconfirmed accounts are deleted after 24h.

If for some reason you've provided incorrect email address during account creation, then you need to wait 24h before you can try to create new account.

Please also check your spam folder for confirmation email.
`;

const disconnectionText = `
There will be times when you will get disconnected from the practice/qualy/race session, especially while driving in AMS2.  
First of all make sure that you're using wired internet connection and not Wi-Fi, then make sure that you don't have any active downloads and that your smart devices (phones/tablets/TVs) don't download anything in the background.

Check if it's Tuesday and if [Steam services](https://steamstat.us/) are up (Tuesday is Steam maintanance day).  
If everything looks good then there are 95% chances that it's AMS2 dedicated server fault and 5% that's it something wrong on \`RCO\` side. In that case create a thread on [Discord support forum](https://discord.com/channels/789131939361062963/1102886413701218366). Describe what goes wrong, add race id or race start time utc and race series name.
Here is [Discord invite](https://discord.gg/racecraft-online-789131939361062963) for your convinience
`;

const sEloText = `
\`S.Elo\` is just your regular \`Elo\` rating with \`Safety\` twist.  

In order to calculate \`S.Elo\`, after each race your \`Elo\` gains are multiplied by \`Safety in Race\`(used as \`%\`), 
For example, let's say you had a lot of incidents and after the race your \`SiR\` was at \`50\`, you've finished first and your \`Elo\` gain should be around \`+16\`, **BUT** for \`S.Elo\` calculation your \`50 SiR\` is treated as \`50%\` muliplier for your gained \`Elo\`, so:
\`+16 * 50% = +8\`  
only \`+8\` (instead of \`+16\`) will be added to your \`S.Elo\` rating. 
Keep in mind that \`SiR%\` multiplier is used only when you **gain** \`S.Elo\` and not when you **lose** it.

The safer you're the higher in ranks you'll be :wink:

S.Elo is calculated only while racing against humans.
`;

const sections = [
  {
    title: "Community resources",
    hashId: "communityResources",
    text: communityResources
  },
  {
    title: "Quick start",
    hashId: "QuickStart",
    text: quickStartText
  },
  {
    title: "racecraft.online (aka RCO)",
    hashId: "RCO",
    text: raceCraftOnlineText
  },
  {
    title: "Troubleshooting",
    hashId: "Troubleshooting",
    text: bugsText
  },
  {
    title: "AMS2 Multiplayer",
    hashId: "Ams2Multiplayer",
    text: ams2Multi
  },
  {
    title: "Can't create account",
    hashId: "accountCreation",
    text: accountCreationText
  },
  {
    title: "Competition structure",
    hashId: "structure",
    text: structureText
  },
  {
    title: "Driver Rating",
    hashId: "DriverRating",
    text: ratingText
  },
  {
    title: "S.Elo Rating",
    hashId: "SElo",
    text: sEloText
  },
  // {
  //   title: "Official races",
  //   hashId: "OfficialRaces",
  //   text: officialRacesText
  // },
  // {
  //   title: "Clean Laps Challenge",
  //   hashId: "CLC",
  //   text: cleanLapsChallengeText
  // },
  {
    title: "Seasons",
    hashId: "Seasons",
    text: seasonsText
  },
  {
    title: "Points",
    hashId: "Points",
    text: pointsText
  },
  {
    title: "Racing License",
    hashId: "RacingLicense",
    text: raceLicenseText
  },
  {
    title: "Racing License requirements",
    hashId: "RacingLicenseRequirement",
    text: raceLicenseRequirementText
  },
  {
    title: "Racing License promotion",
    hashId: "RacingLicensePromotion",
    text: raceLicensePromotionText
  },
  {
    title: "Racing License demotion",
    hashId: "RacingLicenseDemotion",
    text: raceLicenseDemotionText
  },
  {
    title: "Race and practice servers",
    hashId: "Servers",
    text: serversText
  },
  {
    title: "Splits",
    hashId: "Splits",
    text: splitsText
  },
  {
    title: "Regions",
    hashId: "Regions",
    text: regionsText
  },
  // {
  //   title: "Weather",
  //   hashId: "Weather",
  //   text: weatherText
  // },
  // {
  //   title: "Sessions date and time",
  //   hashId: "SessionTime",
  //   text: sessionsDateAndTimeText
  // },
  {
    title: "Adaptive AI for AMS2",
    hashId: "AI",
    text: adaptiveAiText
  },
  {
    title: "Server disconnects",
    hashId: "serverDisconnects",
    text: disconnectionText
  }
];
